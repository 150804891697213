import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from ".";

const ButtonLoading = () => {
  return (
    <div role="status">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="animate-spin"
      >
        <line x1="12" y1="2" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="22"></line>
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
        <line x1="2" y1="12" x2="6" y2="12"></line>
        <line x1="18" y1="12" x2="22" y2="12"></line>
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded border text-sm font-medium shadow-md ring-offset-background transition-colors hover:brightness-90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-gradient-to-b from-white to-gray-200 text-black dark:from-bunker-700 dark:to-bunker-800 dark:text-white",
        active: "bg-gradient-to-b from-bunker-700 to-bunker-800 text-white",
        destructive: "bg-destructive text-destructive-foreground",
        warning: "bg-orange-500 text-destructive-foreground",
        outline:
          "border border-input bg-sand-500 hover:bg-accent hover:text-accent-foreground dark:bg-bunker-950",
        primary: "bg-gradient-to-b from-blue-500 to-blue-800 text-white",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost:
          "border-0 shadow-none hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        normal: "rounded-none border-0 text-black",
        success: "bg-gradient-to-b from-green-500 to-green-800 text-white",
        outline_circle:
          "rounded-full border border-input bg-sand-500 hover:bg-accent hover:text-accent-foreground dark:bg-bunker-950",
      },
      size: {
        default: "h-10 px-3 py-1",
        sm: "h-9 px-2",
        outline_circle: "h-8 w-8 p-1",
        md: "h-12 w-12 px-3 text-lg",
        lg: "h-14 px-10 text-xl",
        icon: "h-10 w-10 p-3",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  loadingText?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant,
      size,
      loadingText,
      loading = false,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        data-touch-sound
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        <>
          {loading && <ButtonLoading />}
          {loading && loadingText ? loadingText : children}
        </>
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
